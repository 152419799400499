<div class="wrapper">
  <div class="sidebar right-sidebar border-left"></div>
  <div class="contents">
    <div class="container">
      <app-page-header-info
        title2="ألموظفين "
        [icon]="'fa-users'"></app-page-header-info>
      <div class="content-container my-7 mx-md-4">
        <div class="row" *ngIf="view === 'list'">
          <div class="col-md-12">
            <div class="mb-7" *ngIf="hrDataService.treeData.length">
              <app-workplace-collapse-view
                [treeData]="hrDataService.treeData"
                [header]="hrDataService.header"></app-workplace-collapse-view>
            </div>
          </div>
        </div>
        <div class="card" [ngClass]="{ 'd-none': view !== 'list' }">
          <div class="card-header">
            <h4><i class="fa fa-users"></i> ألموظفين</h4>
            <div
              class="toolbar d-flex d-inline-flex w-30 justify-content-between">
              <button
                *ngIf="this.hrDataService.selectedWorkplace"
                class="btn btn-print"
                title=" أضافة موظف جديد "
                (click)="newOrEdit('new')">
                <i class="fa fa-user-plus fa-1x"></i>
              </button>

              <button
                class="btn btn-print"
                *ngIf="this.hrDataService.selectedWorkplace"
                (click)="
                  getWorkplaceEmployees(hrDataService.selectedWorkplace._id)
                "
                title="أعادة تحميل ألبيانات">
                <i class="fas fa-sync"></i>
              </button>

              <button
                class="btn btn-print"
                (click)="openDialog()"
                title="أختيار ألمؤسسه">
                <i class="fa fa-university"></i>
              </button>
            </div>
          </div>

          <div class="card-block px-0">
            <table
              id="timeoff"
              datatable
              [dtOptions]="this.dt.dtOptions"
              [dtTrigger]="this.dt.dtTrigger"
              class="table table-bordered table-striped border-0">
              <thead>
                <tr>
                  <th class="width-10">#</th>
                  <th class="width-40">ألاسم</th>
                  <th class="width-40">ألكلية أو ألمركز</th>
                  <th class="text-center width-10">
                    <i class="fa fa-ellipsis-v"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of hrDataService.users; let i = index">
                  <th>{{ i + 1 }}</th>
                  <td>{{ item.fullname }}</td>
                  <td
                    class="overflow-hidden"
                    [title]="
                      this.hrDataService.workplaceService.getFullPath(
                        item.workplace
                      )
                    ">
                    {{
                      this.hrDataService.workplaceService.getMainPath(
                        item.workplace
                      ) || item.workplace.name
                    }}
                  </td>
                  <th class="text-center">
                    <div class="dropdown">
                      <button
                        class="btn"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i class="fa fa-ellipsis-v text-secondary"></i>
                      </button>
                      <ul
                        class="dropdown-menu bg-creamy"
                        aria-labelledby="dropdownMenuButton">
                        <li
                          class="dropdown-item"
                          (click)="
                            this.router.navigate(['/employee/' + item._id])
                          ">
                          <i class="fa fa-user pl-2 pr-1 text-secondary"></i>
                          ملفة ألموظف
                        </li>
                        <!-- <li
                          class="dropdown-item"
                          (click)="newOrEdit('summary'); SelectedUser = item">
                          <i
                            class="fa fa-file-alt pl-2 pr-1 text-secondary"></i>
                          خلاصة ألخدمه
                        </li> -->
                        <li
                          class="dropdown-item"
                          (click)="newOrEdit('edit'); SelectedUser = item">
                          <i
                            class="fa fa-user-edit pl-1 pr- text-secondary"></i>
                          تعديل بيانات ألمستخدم
                        </li>
                        <!-- <li
                          class="dropdown-item"
                          (click)="newOrEdit('password'); SelectedUser = item">
                          <i class="fa fa-key pl-2 pr-1 text-secondary"></i>
                          تغيير كلمة ألمرور
                        </li> -->
                        <!-- <li
                          class="dropdown-item"
                          (click)="newOrEdit('move'); SelectedUser = item">
                          <i
                            class="fa fa-user-minus pl-2 pr-0 text-secondary"></i>
                          نقل ألموظف
                        </li> -->

                        <!-- <li
                          class="dropdown-item"
                          (click)="
                            SelectedUser = item; downloadEmployeeFiles()
                          ">
                          <i
                            class="fa fa-download pl-2 pr-1 text-secondary"></i>
                          تحميل ملفات ألموظف
                        </li> -->
                      </ul>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <app-user-form
          *ngIf="view === 'new'"
          [entityId]="this.hrDataService.selectedWorkplace._id"
          (viewChange)="
            this.getWorkplaceEmployees(this.hrDataService.selectedWorkplace._id)
          ">
        </app-user-form>
        <app-service-summary
          *ngIf="view === 'summary'"
          [userId]="SelectedUser._id"
          (viewChange)="
            this.getWorkplaceEmployees(this.hrDataService.selectedWorkplace._id)
          ">
        </app-service-summary>
        <app-user-form
          *ngIf="view === 'edit' || view === 'password'"
          [entityId]="this.hrDataService.selectedWorkplace._id"
          [userId]="SelectedUser._id"
          [action]="this.view"
          (viewChange)="
            this.getWorkplaceEmployees(this.hrDataService.selectedWorkplace._id)
          ">
        </app-user-form>

        <app-move-user
          *ngIf="view === 'move'"
          [userId]="SelectedUser._id"
          (viewChange)="
            this.getWorkplaceEmployees(this.hrDataService.selectedWorkplace._id)
          ">
        </app-move-user>
      </div>
    </div>
  </div>
  <div class="sidebar left-sidebar border-right"></div>
</div>
