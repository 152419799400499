import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConstantsUtils } from 'src/app/classes/constants';
import { DatePeriod } from 'src/app/classes/Utils';
@Injectable({
  providedIn: 'root'
})
export class VacationUtilService {
  public userId: string = null;
  private viewSubject = new Subject<string>();
  private submissionSubject = new Subject();
  public SelectedVacation: any = null;
  isCurrentDate = false;
  filterDates: DatePeriod = new DatePeriod();

  paymentTypes: string[] = ['راتب كامل', 'راتب تام', 'راتب أسمي', 'بدون راتب'];

  serviceTypes: string[] = ['ألعلاوة وألترفيع', 'ألتقاعد'];

  constructor() {
    this.getCountries();
  }

  changeViewEvent(view: string) {
    this.viewSubject.next(view);
  }

  getViewEvent(): Observable<string> {
    return this.viewSubject.asObservable();
  }

  trigerSubmissionEvent() {
    this.submissionSubject.next(true);
  }
  getSubmissionEvent() {
    return this.submissionSubject.asObservable();
  }

  degreeTypes: string[] = ['دكتوراه', 'ماجستير', 'دبلوم عالي', 'بكالوريوس'];
  public countries: string[] = [];
  public studyTypes: string[] = [
    'داخل ألقطر',
    'نفقة خاصة',
    'بعثة دراسية',
    'زمالة دراسية',
    'زمالة بحثية'
  ];
  getCountries() {
    this.countries = ConstantsUtils.getCountriesList();
  }

  vacationDict: Array<{ _id: string; title: string }> = [
    {
      _id: '',
      title: 'ألأجازات ألمرضيه'
    },
    {
      _id: 'أعتياديه قصيره',
      title: 'ألاجازات ألأعتياديه ألقصيره'
    },
    {
      _id: 'أعتياديه طويله',
      title: 'ألأجازات ألاعتياديه ألطويله'
    },
    {
      _id: 'غياب',
      title: 'ألغيابات'
    },
    {
      _id: 'بدون راتب',
      title: 'ألاجازات بدون راتب'
    },
    {
      _id: 'مرضيه',
      title: 'ألاجازات ألمرضيه'
    },
    {
      _id: 'دراسيه',
      title: 'ألاجازات ألدراسيه'
    },
    {
      _id: 'مصاحبة زوجيه',
      title: 'أجازات ألمصاحبة ألزوجيه'
    },
    {
      _id: 'أعاله',
      title: 'أجازات ألأعالة'
    },
    {
      _id: 'خمس سنوات',
      title: 'أجازة ألخمس سنوات'
    },
    {
      _id: 'أمومه',
      title: 'أجازات ألأمومه'
    },
    {
      _id: 'عجز صحي',
      title: 'أجازات ألعجز ألصحي'
    }
  ];
}
