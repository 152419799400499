import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpLoadingService } from '../shared/http-loading.service';

export type InitialBalance = {
  _id: string;
  userId: string;
  balance: number;
  toDate: string;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: 'root'
})
export class InitialBalanceService {
  private url = environment.ApiUrl + '/initial-balance/';

  constructor(
    private http: HttpClient,
    public loadingService: HttpLoadingService
  ) {}

  findOne(id: string) {
    return this.http.get<InitialBalance | null>(this.url + id);
  }

  createOrUpdate(userId: string, data: any): Observable<any> {
    return this.http.post(this.url + userId, data);
  }
}
